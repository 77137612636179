import { graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import React from "react";

export function HeartIcon(props) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "heart.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return <Image {...props} fluid={data.file.childImageSharp.fluid} />;
}
