import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import { AddressIcon } from "../components/AddressIcon";
import { PhoneNumberIcon } from "../components/PhoneNumberIcon";
import { HeartIcon } from "../components/HeartIcon";
import { css } from "@emotion/core";
import { Logo } from "../components/Logo";
import { Bow } from "../components/Bow";

const arconFont = css`
  font-family: "Arcon", sans-serif;
`;

const arconFontRounded = css`
  font-family: "Arcon Rounded", sans-serif;
`;

const bold = css`
  font-weight: bold;
`;

const regular = css`
  font-weight: regular;
`;

const section = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 0 1rem;
  &:last-of-type {
    padding: 2rem 0 2rem 0;
  }
  & > a {
    text-decoration: none;
    color: initial;
  }
`;

const grayBackground = css`
  background-color: #efeded;
`;

const iconCss = css`
  width: 50px;
  margin: 0 0 1rem 0;
`;

const sectionTitle = [arconFont, bold];
const sectionContent = [arconFontRounded, regular];

const IndexPage = () => (
  <Layout>
    <SEO title="Strona główna" />

    <main>
      <Logo
        css={css`
          width: 200px;
          margin: 2rem auto 0 auto;
        `}
        alt="Jaga logo"
      />
      <h2
        css={css`
          font-weight: regular;
          text-transform: uppercase;
          font-size: 1rem;
          text-align: center;
          padding: 0.5rem 1rem 0 1rem;
        `}
      >
        Centrum Wspierania Rozwoju
      </h2>

      <section css={section}>
        <span css={sectionTitle}>Strona w budowie</span>
        <Bow
          css={css`
            width: 50px;
            margin: 1rem auto 2rem auto;
          `}
        />
      </section>

      <section css={[section, grayBackground]}>
        <AddressIcon css={iconCss} />
        <a
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
          href="https://goo.gl/maps/4yUw1xB9JraFchzc6"
        >
          <span css={sectionTitle}>Marki</span>
          <span css={sectionContent}>ul. Fabryczna 4</span>
        </a>
      </section>

      <section css={[section, grayBackground]}>
        <PhoneNumberIcon css={iconCss} />
        <a css={sectionTitle} href="tel:+48609686940">
          609 686 940
        </a>
        <span css={sectionContent}>Zapraszamy do kontaktu</span>
      </section>

      <section css={[section, grayBackground]}>
        <HeartIcon css={iconCss} />
        <span css={sectionTitle}>Z troski</span>
        <span css={sectionContent}>o naszych najmłodszych!</span>
      </section>
    </main>

    <footer
      itemScope
      itemType="http://schema.org/LocalBusiness"
      css={[
        css`
          flex-grow: 1;

          padding: 1rem;
          display: flex;
          flex-direction: column;
          text-align: center;

          background-color: #212323;
          color: #efeded;

          & > a {
            text-decoration: none;
            color: #efeded;
          }
        `,
        arconFontRounded,
      ]}
    >
      <link itemProp="image" href="/logo.png" />
      <div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
        <meta itemProp="latitude" content="52.320452" />
        <meta itemProp="longitude" content="21.105461" />
      </div>
      <span itemProp="name">Centrum Wspierania Rozwoju JAGA</span>
      <div
        itemProp="address"
        itemScope
        itemType="http://schema.org/PostalAddress"
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <span itemProp="streetAddress">Fabryczna 4</span>
        <span>
          <span itemProp="postalCode">05-260</span>{" "}
          <span itemProp="addressLocality">Marki</span>
        </span>
      </div>

      <a itemProp="telephone" content="+48609686940" href="tel:+48609686940">
        609 686 940
      </a>
      <div
        css={css`
          flex-grow: 1;
        `}
      />
    </footer>
  </Layout>
);

export default IndexPage;
